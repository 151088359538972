import React, { useEffect, useState } from "react";
import Axios from "axios";

import * as constants from "../constants";
import AnswerQuiz from "./AnswerQuiz";

import Modal from "./common/Modal";
import { useNavigate, useParams } from "react-router";
import RenderWebform from "./WebForm/RenderWebform";
import { useRef } from "react";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import { formatDate } from "./courseSection";

const recaptchaRef = React.createRef();

const RECAPTCHA_SITE_KEY = "6LeSUP0mAAAAAJfGBqVzfTdw3gwbNajR7TopXYVB";

const WebForms = (props) => {
  const [modalState, setModalState] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [buttonList, setButtonList] = useState([
    {
      seq: 2,
      buttonClass: "inline-flex justify-center items-center w-[100%] focus:outline-none focus:border-indigo-500 focus:shadow-[0 0 0 3px rgba(90, 72, 232, 0.3)] focus:text-white hover:bg-blue-500 rounded-md border border-transparent bg-blue-600 font-medium text-base text-white shadow-sm transition ease-in-out h-[40px] px-[0.7rem] py-[1.5rem]",
      clickFunction: () => redirectToThankyou(),
      buttonText: "Ok",
    },
  ]);

  const [completedQuizes, setCompletedQuizes] = useState([]);
  const [DropDowns, setDropDowns] = useState({});
  const [webForm, setWebForm] = useState(null);
  const [webFormFields, setWebFormFields] = useState({
    FirstName: "",
    OSHCArranged: false,
    courses: [],
    documents: [],
    preferences: [],
    references: [],
    contacts: [],
    formSignature: null,
    AlreadyHaveUSI: 2,
    QualificationNotCompleted: 2,
    ApplyForRPL: false,
    ApplyForCreditTransfer: false,
    CheckListData: [],
  });
  const navigate = useNavigate();
  const [selectedMailingSuburbID, setSelectedMailingSuburbID] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { quizId } = useParams();
  const sigPadRef = useRef(null);

  useEffect(() => {
    fetchData();

    async function fetchData() {
      try {
        const response = await Axios.get(`${constants.BASEURL}WebForms/${id}`, {
          CollegeID: constants.COLLEGEID,
        });

        if (response) {
          const tempWebForm = response.data;

          const tempWebFormFields = {
            TLWebFormID: tempWebForm.tlWebFormID,
            documents: tempWebForm.uploadDocuments,
            guid: id,
            applicationType: tempWebForm.formType,
            CheckListData: [],
          };

          if (
            window.webformconfigs &&
            window.webformconfigs.defaultWebFormCampus > 0
          ) {
            tempWebForm.webFormCampus = tempWebForm.webFormCampus.filter(
              (c) => c.campusId === window.webformconfigs.defaultWebFormCampus
            );
          }

          if (
            window.webformconfigs &&
            window.webformconfigs.defaultRollingIntake > 0
          ) {
            tempWebForm.intakes = tempWebForm.intakes.filter(
              (c) => c.intakeId === window.webformconfigs.defaultRollingIntake
            );
          }

          tempWebForm.webFormCampus.forEach((c) => {
            c.campusDetail = `${c.campusCode} - ${c.campusName}`;
          });

          if (tempWebForm.formType < 3) {
            tempWebForm.programIntakes = tempWebForm.programIntakes?.map(
              (pi) => ({
                ...pi,
                ...tempWebForm.intakes.find(
                  (intk) => intk.intakeId === pi.intakeId
                ),
              })
            );
          }

          const addressSections = tempWebForm.webFormDetail.formSections.filter(
            (n) => n.formSectionID === 8 || n.formSectionID === 9
          );

          let addressCounter = 0;
          let secondAddressId = 0;

          addressSections.forEach((n, i) => {
            addressCounter++;
            if (addressCounter === 2 && n.formSectionID === 8) {
              secondAddressId = n.formSectionID;
              const copyAddressObject = {
                formFieldID: -1,
                fieldName:
                  n.formSectionID === 8
                    ? "CopyResidentialAddress"
                    : "CopyMailingAddress",
                fieldLabel:
                  n.formSectionID === 8
                    ? "Same as residential address"
                    : "Copy Mailing Address",
                isMandatory: false,
                isFlexible: false,
                fieldType: 8,
                codeType: 0,
                avetCodeType: 0,
                fCodeType: 0,
                extraLabel: null,
                staticData: null,
              };
              n.formFields = [copyAddressObject, ...n.formFields];
            }
          });

          tempWebForm.totalAddresses = addressCounter;
          tempWebForm.targetAddressSectionId = secondAddressId;

          tempWebForm.checkLists && tempWebForm.checkLists.forEach((chkLst) => {
            tempWebFormFields.CheckListData.push(...chkLst.checkListItems);
          });
          setWebForm(tempWebForm);
          setWebFormFields(tempWebFormFields);
          fetchDropDowns(tempWebForm);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [id]);

  const redirectToThankyou = () => {
    if (webForm?.thankYouURL) {
      navigate(webForm?.thankYouURL);
    } else {
      window.location.reload();
    }
  };
  const fetchDropDowns = async (webform) => {
    const { formSections } = webform.webFormDetail;
    let codeType = 0;
    let apiUrl = "";
    try {
      for (const { formFields } of formSections) {
        const citizenshipFormField = formFields.find(
          (ff) => ff.fieldType === 12 && ff.fieldName === "CitizenshipStatus"
        );

        if (citizenshipFormField) {
          if (
            window.webformconfigs &&
            window.webformconfigs.citizenshipStatusSelectionCount !=
              undefined &&
            window.webformconfigs.citizenshipStatusSelectionCount > 0
          ) {
            citizenshipFormField.minSelectionCount =
              window.webformconfigs.citizenshipStatusSelectionCount;
            citizenshipFormField.maxSelectionCount =
              window.webformconfigs.citizenshipStatusSelectionCount;
          } else {
            citizenshipFormField.minSelectionCount = 2;
            citizenshipFormField.maxSelectionCount = 2;
          }
        }

        const fieldsToFetch = formFields.filter((ff) =>
          [2, 6, 7, 10].includes(ff.fieldType)
        );

        for (const formField of fieldsToFetch) {
          codeType = formField.codeType;
          apiUrl = "UserCode/getUserCode?codetype=";

          if (formField.avetCodeType > 0) {
            apiUrl = "AvetCode/getAvetCode?codeType=";
            codeType = formField.avetCodeType;
          } else if (formField.fCodeType > 0) {
            apiUrl = "FeeHelpCode/getFeeHelpCode?codeType=";
            codeType = formField.fCodeType;
          }

          if (codeType > 0) {
            try {
              const response = await Axios.get(
                `${constants.BASEURL}${apiUrl}${codeType}`,
                {
                  CollegeID: constants.COLLEGEID,
                }
              );

              let type = 0;
              if (formField.codeType > 0) {
                type = 1;
              } else if (formField.avetCodeType > 0) {
                type = 2;
              } else if (formField.fCodeType > 0) {
                type = 3;
              }

              parseCodes(type, response.data);
              if (
                parseInt(type) === 2 &&
                parseInt(formField.avetCodeType) === 2
              ) {
                DropDowns[formField.fieldName] = response.data.filter(
                  (item) => item.avetCode !== "@"
                );
              } else {
                DropDowns[formField.fieldName] = response.data;
              }
              setDropDowns(DropDowns);
            } catch (error) {
              console.log("Error while fetching " + formField.fieldName, error);
            }
          }
        }
      }
    } catch (e) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const checkValidation = (section) => {
    section?.map((partSection) => {
      const keys = Object.keys(partSection);
      const { error } = partSection;
      keys?.map(function (key) {
        if (key !== "error") {
          if (partSection[key] === null || partSection[key] === undefined) {
            error[key] = true;
            return;
          }

          if (
            partSection[key].constructor === String().constructor &&
            partSection[key].trim() !== "" &&
            partSection[key].trim() !== "0"
          ) {
            error[key] = false;
            return;
          }
          error[key] = true;
        }
      }, partSection);
      return section;
    });
  };
  const formatDate = (date) => {
    const dt = moment(date).startOf("dayt").utc();
    // return dt.toISOString();
    return moment(date).format("YYYY-MM-DD");
  };
  const isValidDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    // return !isNaN(dateObj) && dateObj.toISOString().slice(0, 10) === dateStr;
    const isoDateTimeRegex =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
    return isoDateTimeRegex.test(dateStr) && !isNaN(new Date(dateStr));
  };
  const submitForm = async () => {
    let tempWebForm = { ...webForm };
    let tempWebFormFields = { ...webFormFields };
    const recaptchaValue = recaptchaRef.current.getValue();
    const { formSections } = tempWebForm?.webFormDetail;
    const { courses, preferences, references, contacts } = tempWebFormFields;
    let hasErrors = false;
    let hasSignatureSection = false;
    if (tempWebForm.formType === 4) {
      preferences = checkValidation(preferences);
    } else if (tempWebForm.formType === 5) {
      references = checkValidation(references);
      contacts = checkValidation(contacts);
    } else if (tempWebForm.formType <= 3) {
      courses?.map((course) => {
        const keys = Object.keys(course);
        const { error } = course;
        const { courseType } = { tempWebForm };
        keys?.map(function (key) {
        
          if (key !== "error" && key !== "HolidayWeeks") {
            if (
              courseType === 9 &&
              (course[key] === "UOS" || course[key] === "Subject")
            ) {
              error[key] = false;
              return;
            }
            if (courseType === 22 && course[key] === "UOS") {
              error[key] = false;
              return;
            }

            if (courseType === 23 && course[key] === "Subject") {
              error[key] = false;
              return;
            }

            if (course[key] === null || course[key] === undefined) {
              error[key] = true;
              return;
            }
            if (
              course[key].constructor === Number().constructor &&
              course[key] > 0
            ) {
              error[key] = false;
              return;
            }
            if (
              course[key].constructor === String().constructor &&
              course[key].trim() !== "" &&
              course[key].trim() !== "0"
            ) {
              error[key] = false;
              return;
            }

            if (
              course[key] instanceof moment ||
              course[key].constructor === new Date().constructor ||
              typeof course[key] == "object"
            ) {
              error[key] = false;
              return;
            }
            error[key] = true;
          }
        }, course);
      });
    }
    try {
      if (!hasErrors) {
        if (tempWebForm.uploadDocuments) {
          tempWebForm.uploadDocuments?.map((dt) => {
            if (dt.mandatory) {
              if (dt.files && dt.files?.length > 0) {
                dt.error = false;
              } else {
                dt.error = true;
                hasErrors = true;
              }
            }
          });
        }
        formSections?.map(({ formSectionID, formFields }) => {
          if (formSectionID !== 29 && formSectionID !== 34) {
            formFields?.map((formField) => {
              formField.error = false;
              if (formField?.isMandatory) {
                if (
                  formField?.fieldName === "Email" &&
                  tempWebFormFields[formField?.fieldName] &&
                  tempWebFormFields[formField?.fieldName].trim() !== ""
                ) {
                  var pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                  );

                  if (!pattern.test(tempWebFormFields[formField.fieldName])) {
                    formField.error = true;
                    return;
                  }
                } else if (
                  formField.fieldName === "Mobile" &&
                  tempWebFormFields[formField?.fieldName] &&
                  tempWebFormFields[formField?.fieldName].trim() !== ""
                ) {
                  var pattern = new RegExp(/^[0-9\b]+$/);

                  pattern = new RegExp(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                  );
                  if (!pattern.test(tempWebFormFields[formField.fieldName])) {
                    formField.error = true;
                    return;
                  }
                } else if (
                  tempWebFormFields[formField.fieldName] &&
                  (tempWebFormFields[formField.fieldName].constructor ===
                    new Boolean().constructor ||
                    tempWebFormFields[formField.fieldName].constructor ===
                      new Array().constructor)
                ) {
                  formField.error = false;
                  return;
                }
                if (!tempWebFormFields[formField?.fieldName]) {
                  formField.error = true;
                  hasErrors = true;
                } else if (
                  tempWebFormFields[formField?.fieldName] &&
                  tempWebFormFields[formField?.fieldName].constructor !==
                    new Date().constructor &&
                  tempWebFormFields[formField?.fieldName].constructor !==
                    new Number().constructor &&
                  !(tempWebFormFields[formField.fieldName] instanceof moment) &&
                  tempWebFormFields[formField.fieldName] ==
                    new String().constructor &&
                  tempWebFormFields[formField.fieldName].trim() === ""
                ) {
                  formField.error = true;
                  hasErrors = true;
                } else if (
                  tempWebFormFields[formField.fieldName].constructor ===
                    new Number().constructor &&
                  !(parseInt(tempWebFormFields[formField.fieldName]) > 0)
                ) {
                  formField.error = true;
                  hasErrors = true;
                } else if (formField.minSelectionCount > 0) {
                  let cVal = tempWebFormFields[formField.fieldName];
                  if (cVal.split(",")?.length < formField.minSelectionCount) {
                    formField.error = true;
                    hasErrors = true;
                  }
                }
                if (formField.fieldName === "PriorAchievments") {
                  if (
                    tempWebFormFields["PriorAchievments"] &&
                    tempWebFormFields["PriorAchievments"] !== ""
                  ) {
                    let strPriorAchievements =
                      tempWebFormFields["PriorAchievments"].split(",");
                    let errorFlag = false;
                    strPriorAchievements.forEach(function (item, index) {
                      if (item.indexOf("|") < 0) {
                        formField.error = true;
                        hasErrors = true;
                        return;
                      }
                    });
                  }
                }
              } else if (
                formField.fieldName === "CollegeSetUpUSI" ||
                formField.fieldName === "USIPermission"
              ) {
                if (
                  !tempWebFormFields["CollegeSetUpUSI"] &&
                  !tempWebFormFields["USIPermission"]
                ) {
                  formField.error = true;
                  hasErrors = true;
                }
              }
            });
          }
        });

        const customSections = [
          ...formSections.filter(
            (fs) =>
              fs.formSectionID === 3 ||
              fs.formSectionID === 4 ||
              fs.formSectionID === 34 ||
              fs.formSectionID === 40
          ),
        ];

        customSections?.map((section) => {
          section.error = false;
          if (section.formSectionTitle === "Terms and Conditions") {
            if (!tempWebFormFields["TermsChecked"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Student Declaration") {
            if (!tempWebFormFields["DeclarationChecked"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Personal Consent") {
            if (!tempWebFormFields["PersonalConsent"]) {
              hasErrors = true;
              section.error = true;
            }
          }
          if (section.formSectionTitle === "Signature") {
            hasSignatureSection = true;
            if (!sigPadRef.current) {
              hasErrors = true;
              section.error = true;
            } else {
              if (sigPadRef.current?.isEmpty()) {
                hasErrors = true;
                section.error = true;
              }
            }
          }
        });
      }
    } catch (e) {
      console.log("error", e);
      alert(
        "An error occured while validating form. Please try again after some time."
      );
      return;
    }
    // vaidation failed
    if (hasErrors) {
      setWebFormFields(tempWebFormFields);
      setWebForm(tempWebForm);
      console.log("tempWebFormFields error", tempWebFormFields);
      alert(
        "Your application submitted has errors. Please review your answers above and fix any errors."
      );
      return;
    } else {
      if (recaptchaValue === "") {
        alert("Please verify that you are not a robot.");
        return;
      }
      setModalMessage("Saving Form please wait");
      setModalState(true);
      let webFormFinal = JSON.parse(JSON.stringify(tempWebFormFields));

      for (let field of Object.keys(webFormFinal)) {
        if (["courses", "contacts", "preferences"].includes(field)) {
          for (let el of Object.keys(webFormFinal[field])) {
            for (let attr of Object.keys(webFormFinal[field][el])) {
              if (
                webFormFinal[field][el][attr] &&
                webFormFinal[field][el][attr].constructor !==
                  new Number().constructor &&
                (webFormFinal[field][el][attr] instanceof Date ||
                  !isNaN(Date.parse(webFormFinal[field][el][attr])) ||
                  moment.isMoment(webFormFinal[field][el][attr]))
              ) {
                let dateFormat = "";
                if (moment.isMoment(webFormFinal[field][el][attr])) {
                  // dateFormat = formatDate(
                  //   moment(webFormFinal[field][el][attr]).toDate()
                  // );
                  dateFormat = formatDate(webFormFinal[field][el][attr]);
                } else {
                  // dateFormat = formatDate(

                  //   moment(webFormFinal[field][el][attr]).toDate()
                  // );
                  dateFormat = formatDate(webFormFinal[field][el][attr]);
                }
                webFormFinal[field][el][attr] = dateFormat;
              }
            }
          }
        } else if (
          webFormFinal[field] &&
          webFormFinal[field].constructor !== new Number().constructor &&
          webFormFinal[field].constructor == new String().constructor &&
          isValidDate(webFormFinal[field]) &&
          (webFormFinal[field] instanceof Date ||
            !isNaN(Date.parse(webFormFinal[field])))
        ) {
          let dateFormat = formatDate(webFormFinal[field]);

          webFormFinal[field] = dateFormat;
        }
      }

      webFormFinal.contacts?.map((c) => {
        c.Position = parseInt(c.Position);
      });
      webFormFinal.courses?.map((c) => {
        c.Campus = parseInt(c.Campus);
        c.Intake = parseInt(c.Intake);
        c.Program = parseInt(c.Program);
        c.AgentId = parseInt(webFormFinal.AgentId);
      });
      webFormFinal.preferences?.map((c) => {
        c.Campus = parseInt(c.Campus);
        c.Program = parseInt(c.Program);
      });
      if (webFormFinal.LastSchoolYear)
        webFormFinal.LastSchoolYear = parseInt(webFormFinal.LastSchoolYear);
      if (webFormFinal.NoOfStudentReferred)
        webFormFinal.NoOfStudentReferred = parseInt(
          webFormFinal.NoOfStudentReferred
        );
      if (webFormFinal.PersonalConsent)
        webFormFinal.PersonalConsent = parseInt(webFormFinal.PersonalConsent);
      const webFormPost = { ...webFormFinal };
      if (webFormFinal.Disability && webFormFinal.Disability?.length > 0) {
        webFormPost.Disability = webFormFinal.Disability?.map(
          (x) => x.avetCodeID
        ).toString();
        webFormFinal.Disability = webFormPost.Disability;
      }
      webFormFinal.TaxFileNumber = parseInt(webFormFinal.TaxFileNumber) || 0;
      webFormFinal.CommonwealthEducationStudentSupportNumber =
        parseInt(webFormFinal.CommonwealthEducationStudentSupportNumber) || 0;
      webFormFinal.YearOfArrival = parseInt(webFormFinal.YearOfArrival) || 0;
      webFormFinal.YearofCompletionofHighestEducation =
        parseInt(webFormFinal.YearofCompletionofHighestEducation) || 0;
      webFormFinal.CitizenResidentIndicator =
        parseInt(webFormFinal.CitizenResidentIndicator) || 0;
      webFormFinal.Country = parseInt(webFormFinal.Country) || 0;

      // console.log("testing", webFormFinal);

      try {
        const result = await Axios.post(
          constants.BASEURL + "WebForms/SaveWebForm",
          webFormFinal
        );
        if (result) {
          let message = result.data;
          if (message.status === 200) {
          } else {
            throw result.data.message;
          }
          if (hasSignatureSection) {
            storeSignaturesSync(webFormFinal, message.applicantId);
          }
          storeDocumentsSync(tempWebFormFields, message.applicantId, 0);
        }
      } catch (ex) {
        alert("An Error occured while saving webform");
        setModalState(false);
      }
    }
  };

  const parseCodes = (type, data) => {
    switch (type) {
      case 1:
        data = data.forEach((d) => {
          if (d.value > 0 && d.codetype !== 3) {
            d.idField = d.value;
          } else {
            d.value = d.userCodeId;
            d.idField = d.userCodeId;
          }
          d.valueField = d.codeName;
        });
        break;
      case 2:
        if (data[0].avetCodeType === 2) {
          data = data.filter((item) => item.avetCode !== "@");
        }
        data = data.forEach((d) => {
          d.value = d.avetCodeID;
          d.idField = d.avetCodeID;
          d.valueField = d.avetDescription;
        });
        break;
      case 3:
        data = data.forEach((d) => {
          d.value = d.feeHelpCodeId;
          d.idField = d.feeHelpCodeId;
          d.valueField = `${d.feeHelpCode} - ${d.feeHelpCodeName}`;
        });
        break;
    }
  };

  const resetMandatory = (e, name) => {
    const { formSections } = webForm.webFormDetail;

    for (const { formFields } of formSections) {
      const formField = formFields.find((field) => field.fieldName === name);
      if (formField) {
        formField.isMandatory = e.target.checked;
        break;
      }
    }
  };

  const uncheckMandatory = (e, name) => {
    const { formSections } = webForm.webFormDetail;

    for (const { formFields } of formSections) {
      const formField = formFields.find((field) => field.fieldName === name);
      if (formField) {
        formField.isMandatory = false;
        break;
      }
    }
  };

  const copyAddress = (e, type) => {
    const tempWebFormFields = { ...webFormFields };

    if (e.target.checked) {
      const PropertyName = tempWebFormFields.PropertyName || "";
      const StreetNo = tempWebFormFields.StreetNo || "";
      let localaddress2 = "";

      if (PropertyName.trim() !== "") {
        tempWebFormFields.MailingAddressLine1 = PropertyName;
        localaddress2 = tempWebFormFields.FlatDetail || "";

        if (StreetNo.trim() !== "") {
          localaddress2 =
            localaddress2.trim() !== "" ? `${localaddress2}/` : "";
          localaddress2 += StreetNo.trim();
        }

        localaddress2 = localaddress2.trim() !== "" ? `${localaddress2} ` : "";
        localaddress2 += tempWebFormFields.StreetName || "";

        tempWebFormFields.MailingAddressLine2 = localaddress2;
      } else {
        localaddress2 = tempWebFormFields.FlatDetail || "";

        if (StreetNo.trim() !== "") {
          localaddress2 = localaddress2 !== "" ? `${localaddress2}/` : "";
          localaddress2 += StreetNo.trim();
        }

        if (localaddress2.trim() !== "") {
          localaddress2 += " ";
        }
        localaddress2 += tempWebFormFields.StreetName || "";

        tempWebFormFields.MailingAddressLine1 = localaddress2;
      }

      setSelectedMailingSuburbID(tempWebFormFields.selectedAvetmissSuburbID);
      tempWebFormFields.MailingSuburbID =
        tempWebFormFields.AvetmissSuburbID || "";
      tempWebFormFields.MailingSuburbIDState =
        tempWebFormFields.AvetmissSuburbIDState || "";
      tempWebFormFields.MailingSuburbIDPostCode =
        tempWebFormFields.AvetmissSuburbIDPostCode || "";
      tempWebFormFields.MailingSuburbIDCountry =
        tempWebFormFields.AvetmissSuburbIDCountry || "";

      setWebFormFields(tempWebFormFields);
    }
  };
  const handleChange = (e, name) => {
    let tempWebForm = { ...webForm };
    let tempWebFormFields = { ...webFormFields };
    const { formSections } = tempWebForm.webFormDetail;
    let controlFound = false;
    let controlName = "";

    if (name) {
      let orgName = name?.action ? name.name : name;
      tempWebFormFields[orgName] = e; //instanceof Date ? e : e;
      controlName = name;
    } else {
      const fieldName = e.target.name;
      const fieldValue =
        e.target.type === "checkbox"
          ? e.target.checked
          : e.target.type === "select-one"
          ? parseInt(e.target.value)
          : e.target.type === "radio"
          ? JSON.parse(e.target.value)
          : e.target.value;

      tempWebFormFields[fieldName] = fieldValue;
      controlName = fieldName;

      if (controlName === "ReferredByAgent") {
        resetMandatory(e, "AgentId");
      } else if (controlName === "USIPermission") {
        resetMandatory(e, "UniqueStudentIdentifier");
      } 

      // Comment - If checkbox is checked than making the following fields mandatory otherwise not
      else if (controlName === "AgentNotInList") {
        resetMandatory(e, "NewAgentCode")
        resetMandatory(e, "NewAgentName")
        uncheckMandatory(e, "AgentId")
      }

      else if (controlName === "CopyResidentialAddress") {
        copyAddress(e, 2);
      } else if (controlName === "AlreadyHaveUSI") {
        if (tempWebFormFields["AlreadyHaveUSI"]) {
          if (JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 1) {
            tempWebFormFields["CollegeSetUpUSI"] = undefined;
            tempWebFormFields["TownOfBirth"] = undefined;
            tempWebFormFields["BirthCountry"] = undefined;
          } else {
            tempWebFormFields["USIPermission"] = undefined;
            resetMandatory(e, "UniqueStudentIdentifier");
            tempWebFormFields["UniqueStudentIdentifier"] = undefined;
          }
        }
      }
    }

    formSections.forEach(({ formFields }) => {
      if (!controlFound) {
        formFields.forEach((formfield) => {
          if (
            formfield.fieldName === controlName &&
            tempWebFormFields[controlName]
          ) {
            if (
              typeof tempWebFormFields[controlName] === "string" &&
              tempWebFormFields[controlName].trim() !== "" &&
              formfield.error
            ) {
              formfield.error = false;
            }

            if (
              tempWebFormFields[controlName] instanceof Date &&
              formfield.error
            ) {
              formfield.error = false;
            }

            if (
              typeof tempWebFormFields[controlName] === "number" &&
              formfield.error
            ) {
              formfield.error = false;
            }
          }
        });
      }
    });
    setWebFormFields(tempWebFormFields);
    setWebForm(tempWebForm);
  };
  const storeSignaturesSync = async (webFormFields, applicantId) => {
    const canvas = sigPadRef.current.getCanvas();
    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });

    const form = new FormData();
    form.append(
      "file",
      blob,
      `${webFormFields.FirstName.trim()}${webFormFields.LastName.trim()}_signature.png`
    );
    form.append("applicantId", applicantId);
    form.append("docTypeId", 0);
    form.append("applicantType", webFormFields.applicationType);
    form.append("isSignature", 1);

    try {
      const result = await Axios.post(
        `${constants.BASEURL}WebForms/SaveFormDocuments`,
        form
      );

      if (!result.data.success) {
        setModalMessage("Unabe to upload signature.");
      } else {
        setModalMessage("Signature uploaded.");
      }
    } catch (e) {
      setModalMessage(
        "An error occurred while uploading signature. Please try again later."
      );
    }
  };

  const storeDocumentsSync = async (webFormFields, applicantId, iteration) => {
    const { documents } = webFormFields;

    if (iteration < (documents?.length || 0)) {
      const form = new FormData();

      if (documents[iteration].files) {
        for (const file of documents[iteration].files) {
          form.append("file", file.originFileObj);
        }

        form.append("applicantId", applicantId);
        form.append("docTypeId", documents[iteration].docTypeID);
        form.append("applicantType", webFormFields.applicationType);

        try {
          const result = await Axios.post(
            `${constants.BASEURL}WebForms/SaveFormDocuments`,
            form
          );

          if (!result.data.success) {
            setModalMessage(`${documents[iteration].docType} failed.`);
          } else {
            setModalMessage(`${documents[iteration].docType} uploaded.`);
            storeDocumentsSync(webFormFields, applicantId, iteration + 1);
          }
        } catch (e) {
          setModalMessage(
            "An error occurred while uploading documents. Please try again later."
          );
        }
      } else {
        storeDocumentsSync(webFormFields, applicantId, iteration + 1);
      }
    } else {
      setTimeout(() => {
        setModalMessage("Form Submitted Successfully.");
      }, 3000);
    }
  };

  const afterQuizCompleted = (quizId) => {
    const completedQuiz = [...completedQuizes, quizId];
    const allQuiz = webForm.quizIds.split(",");
    const pendingQuiz = allQuiz.filter((x) => !completedQuiz.includes(x));

    setCompletedQuizes(completedQuiz);

    if (pendingQuiz.length > 0) {
      const nextQuizId = pendingQuiz[0];
      navigate(`/webform/${id}/application/1/quiz/${nextQuizId}`);
      window.location.reload();
    }
  };
  const clearSignature = () => {
    if (sigPadRef.current && sigPadRef.current.clear) {
      webFormFields.formSignature = null;
      setWebFormFields(webFormFields);
      sigPadRef.current.clear();
    }
  };

  const trim = () => {
    if (sigPadRef.current && sigPadRef.current.getTrimmedCanvas) {
      webFormFields.formSignature = sigPadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setWebFormFields(webFormFields);
    }
  };

  const updatePreference = (preferences) => {
    setWebFormFields((prevState) => ({ ...prevState, preferences }));
  };

  const updateCourse = (courses) => {
    setWebFormFields((prevState) => ({ ...prevState, courses }));
  };

  const setFiles = (files, docType) => {
    const tempWebFormFields = { ...webFormFields };
    const currentDocumentObj = tempWebFormFields.documents.find(
      (doc) => doc.docTypeID === docType
    );
    if (currentDocumentObj) {
      currentDocumentObj.files = files;
      setWebFormFields(tempWebFormFields);
    }
  };

  const updateReference = (references) => {
    setWebFormFields((prevState) => ({ ...prevState, references }));
  };

  const updateContact = (contacts) => {
    setWebFormFields({ ...webFormFields, contacts });
  };
  const handleCheckListChange = (changedItem) => {
    const tempWebFormFields = webFormFields;

    tempWebFormFields?.CheckListData?.map((itm) => {
      if (
        changedItem &&
        itm.checkListId === changedItem?.checkListId &&
        itm.checkListItemId === changedItem?.checkListItemId
      ) {
        return (itm.optionYesNo = parseInt(changedItem?.optionYesNo));
      }
      return itm;
    });
    setWebFormFields(tempWebFormFields);
  };

  const handleCheckBoxGroup = (e, dataRow) => {
    const tempWebFormFields = { ...webFormFields };
    let currentSelection = tempWebFormFields[e.target.name] || "";

    if (e.target.checked) {
      if (currentSelection !== "") {
        currentSelection += ",";
      }
      currentSelection += e.target.value;
    } else {
      const values = currentSelection.split(",");
      currentSelection = values
        .filter((val) => val !== e.target.value)
        .join(",");
    }

    tempWebFormFields[e.target.name] = currentSelection;
    setWebFormFields(tempWebFormFields);
  };

  const handleAchievementsChange = (e, dataRow) => {
    const tempDropDowns = { ...DropDowns };
    const tempWebFormFields = { ...webFormFields };
    dataRow[e.target.name] =
      e.target.type === "checkbox"
        ? e.target.checked
        : e.target.type === "select-one"
        ? e.target.value
        : e.target.value;

    if (
      tempWebFormFields.HasPriorAchievments &&
      tempDropDowns["PriorAchievments"]
    ) {
      const achievements = tempDropDowns["PriorAchievments"];
      tempWebFormFields.PriorAchievments = "";
      achievements.forEach((ach) => {
        if (ach.chkachievement) {
          if (tempWebFormFields.PriorAchievments !== "") {
            tempWebFormFields.PriorAchievments += ",";
          }
          tempWebFormFields.PriorAchievments += ach.avetCodeID;
          if (ach.selachievement) {
            tempWebFormFields.PriorAchievments += "|" + ach.selachievement;
          }
        }
      });
    }
    setDropDowns(tempDropDowns);
    setWebFormFields(tempWebFormFields);
  };

  const handleAgent = (fieldName, selectedAgent) => {
    const tempWebFormFields = { ...webFormFields };
    tempWebFormFields[fieldName] = selectedAgent.agentID;
    setWebFormFields(tempWebFormFields);
  };

  const handleSuburb = (fieldName, selectedSuburb) => {
    const tempWebFormFields = { ...webFormFields };

    if (window.webformconfigs && window.webformconfigs.showStateAbbr) {
      tempWebFormFields[fieldName + "State"] = selectedSuburb.stateAbbr || "";
    } else {
      tempWebFormFields[fieldName + "State"] = selectedSuburb.state || "";
    }

    tempWebFormFields[fieldName + "PostCode"] = selectedSuburb.postCode || "";
    tempWebFormFields[fieldName + "Country"] = selectedSuburb.country || "";
    tempWebFormFields[fieldName] = selectedSuburb.suburbID;
    tempWebFormFields["selected" + fieldName] = selectedSuburb;
    setWebFormFields(tempWebFormFields);
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      {!loading && quizId > 0 && (
        <AnswerQuiz
          afterUpdate={afterQuizCompleted}
          quizId={id}
          objectType={1}
          objectId={1}
        />
      )}
      {!loading && !quizId && (
        <>
          <div className="px-8 py-4">
            <form
              name="webform"
              layout="vertical"
              style={{ marginBottom: "2rem" }}
            >
              <RenderWebform
                webForm={webForm}
                webFormFields={webFormFields}
                handleChange={handleChange}
                setWebForm={setWebForm}
                setWebFormFields={setWebFormFields}
                DropDowns={DropDowns}
                setDropDowns={setDropDowns}
                sigPadRef={sigPadRef}
                updatePreference={updatePreference}
                updateCourse={updateCourse}
                setFiles={setFiles}
                updateReference={updateReference}
                updateContact={updateContact}
                clearSignature={clearSignature}
                trim={trim}
                handleCheckListChange={handleCheckListChange}
                handleCheckBoxGroup={handleCheckBoxGroup}
                handleAchievementsChange={handleAchievementsChange}
                handleAgent={handleAgent}
                handleSuburb={handleSuburb}
              />
              <div className="flex justify-center">
                <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} />
              </div>

              <div className="flex justify-end pr-4">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={submitForm}
                    className="flex items-center px-4 py-2 font-medium text-white bg-blue-600 cursor-pointer shadow hover:bg-blue-500 rounded-md"
                    style={{ width: "fit-content" }}
                  >
                    {webForm && webForm?.quizIds !== "" ? "Next >" : "Submit"}
                  </button>
                </span>
              </div>
            </form>
          </div>
          <Modal
            modalState={modalState}
            buttonsList={
              modalMessage === "Form Submitted Successfully." ? buttonList : []
            }
            headingText="Form Status"
          >
            <div className="text-center">{modalMessage}</div>
          </Modal>
        </>
      )}
      {loading && (
        <div className="flex grow justify-center items-center w-[100%] h-[100vh]">
          <AiOutlineLoading3Quarters  className="w-16 h-16 fill-blue-400 animate-spin"/>
        </div>
      )}
    </div>
  );
};

export default WebForms;
